import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "checkbox", // checkboxes with values
    "actions", // element containing action links
  ]

  connect() {
    this.values = new Set();
    if (this.data.has('previewEl')) {
      this.previewEl = this.actionsTarget
        .querySelector(this.data.get('previewEl'))
    }
    if (this.actionsTarget.classList.contains('d-none')) {
      this.hideable = true
    }
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        this.addCheckbox(checkbox)
      }
    })
    this.toggleActions()
  }

  onToggleAll(event) {
    this.checkboxTargets.forEach(checkbox => {
      this.toggleCheckbox(checkbox, event.target.checked)
    });
    this.updateURLSearchParams();
  }

  onToggleCheckbox(event) {
    this.toggleCheckbox(event.target, event.target.checked)
    this.updateURLSearchParams();
  }

  toggleCheckbox(checkbox, toggle) {
    if (toggle)
      this.addCheckbox(checkbox)
    else
      this.removeCheckbox(checkbox)
  }

  toggleActions() {
    if (this.hideable) {
      this.actionsTarget.classList.toggle('d-none', !this.values.size)
    }
    this.actionsTarget
    .querySelectorAll('[data-action*="checkboxes-actions"]')
    .forEach(el => {
      const minimumSelected = el.dataset.minimumSelected || 1
      el.classList.toggle(
        'disabled',
        this.values.size < minimumSelected
      )
    })
  }

  addCheckbox(checkbox) {
    checkbox.checked = true;
    this.values.add(checkbox.value);
    this.displayValues();
    this.toggleActions();
  }

  removeCheckbox(checkbox) {
    checkbox.checked = false;
    this.values.delete(checkbox.value);
    this.displayValues();
    this.toggleActions();
  }

  displayValues() {
    if (this.previewEl) {
      const content = Array.from(this.values).join(', ')
      this.previewEl.innerText = content;
    }
  }

  updateActionHref(event) {
    const a = event.currentTarget;
    const newURL = new URL(a.href);
    this.assignSearchParamsValues(newURL.searchParams, a.dataset.paramKey)
    if (a.dataset.appendCurrentSearchParams)
      this.appendCurrentSearchParams(newURL)
    a.href = newURL
  }

  updateURLSearchParams() {
    const params = new URLSearchParams(location.search);
    this.assignSearchParamsValues(params)
    history.replaceState({}, '', `${location.pathname}?${params}`);
  }

  assignSearchParamsValues(params, keyName) {
    keyName = keyName || 'record_ids[]'
    if (this.values.size) {
      const [first, ...tail] = this.values;
      params.set(keyName, first);
      tail.forEach(value => params.append(keyName, value));
    } else {
      params.delete(keyName)
    }
    return params;
  }

  appendCurrentSearchParams(url) {
    const currentSearchParams = new URLSearchParams(location.search)
    const searchParamName = "q[s]"
    if (currentSearchParams.get(searchParamName)) {
      currentSearchParams.set(searchParamName, "updated_at desc")
    }
    currentSearchParams.forEach((value, key) => {
      if (!url.searchParams.has(key)) {
        url.searchParams.set(key, value);
      } else if (!url.searchParams.getAll(key).includes(value)) {
        url.searchParams.append(key, value)
      }
    })
  }

}
