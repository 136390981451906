import { get } from "@rails/request.js"
import Select2DdynamicController from "../select2_dynamic_controller";

export default class extends Select2DdynamicController {
  static values = {
    url: String,
    flatpickrSelector: String,
    weekdaySchedulesUrl: String,
    flatpickrDateFrom: String,
    flatpickrDateTo: String
  }

  init() {
    $(this.element).select2(this.select2_options)
    $(this.element).on("select2:select", this.on_select.bind(this));
  }

  process_results(data) {
    return {
      results: data.data.map(item => {
        return {
          id: item.id,
          text: item.attributes[this.textKeyValue],
          on_premise: item.attributes["on_premise"]
        }
      }),
      // define if has more pages
      pagination: { more: !!data.links.next }
    }
  }

  on_select(event) {
    if (event.params?.data.on_premise) {
      this.update_flatpickr(event.params.data.id, this.flatpickr_input.flatpickr.fp)
      this.hide_flatpickr(false)
    } else {
      this.hide_flatpickr(true)
    }
  }

  hide_flatpickr(force) {
    this.flatpickr_input.closest(".form-group").classList.toggle("d-none", force)
    this.flatpickr_input.disabled = force
  }

  get flatpickr_input() {
    return document.querySelector(this.flatpickrSelectorValue)
  }

  async update_flatpickr(location_id, flatpickr) {
    let url = this.weekdaySchedulesUrlValue.replace("ID", location_id)
    const response = await get(url, {
      query: {
        from: this.flatpickrDateFromValue,
        to: this.flatpickrDateToValue
      }
    })
    if (response.ok) {
      const data = await response.json
      const availableDates = data.map((d) => new Date(d))
      flatpickr.set("enable", availableDates)
      flatpickr.setDate(flatpickr.selectedDates.filter(item => availableDates.includes(item)))
    }
  }
}
