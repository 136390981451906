import Select2DdynamicController from "../select2_dynamic_controller";

export default class extends Select2DdynamicController {
  static values = {
    url: String,
    copyRecordOutside: String,
    outsideTrueSelector: String,
    orderLocationSelector: String
  }

  init() {
    $(this.element).select2(this.select2_options)
    $(this.element).on("select2:select", (e) => {
      const outside_true_input = document.querySelector(this.outsideTrueSelectorValue)
      const order_location_input = document.querySelector(this.orderLocationSelectorValue)

      outside_true_input.value = this.copyRecordOutsideValue && e.params.data.library_card_type_outside ? "" : "false";
      order_location_input.dataset["v-Select2OrderLocationFlatpickrDateToValue"] = e.params.data.expiration_date
      order_location_input.disabled = false
    });
  }

  process_results(data) {
    return {
      results: data.data.map(item => {
        return {
          id: item.id,
          text: item.attributes[this.textKeyValue],
          library_card_type_outside: item.attributes["library_card_type_outside"],
          expiration_date: item.attributes["expiration_date"]
        }
      }),
      // define if has more pages
      pagination: { more: !!data.links.next }
    }
  }
}
